



































import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';
import { ConfigurationPoolTableHeaders } from './model/ConfigurationPool.model';
import { BaseConfigClass } from './BaseConfig.class';
import { ConfigType } from './model/Config.model';

@Component({
  components: { TmsTable }
})
export default class ConfigurationDivisions extends Vue {
  columns: TableHeaders[] = ConfigurationPoolTableHeaders;
  config = new BaseConfigClass(ConfigType.POOLS);

  created() {
    this.config.getData();
  }

  // getTableData(isActive) {
  //   const rows = this.config.rows;
  //   if (typeof isActive === 'boolean') {
  //     return rows.filter(row => row.active === isActive);
  //   }

  //   return rows;
  // }
}
